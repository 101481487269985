h5 {
  text-align: left;
  line-height: 1em;
  margin: 0;
  color: var(--secondary-color);
}

button {
  background-color: var(--primary-color);
  margin-left: 8px;
  border-radius: 12px;
  color: var(--secondary-color);
  border-color: var(--secondary-color);
  padding: 4px 8px 4px 8px;
  cursor: pointer;
}

.App {
  text-align: center;
}

.App-header {
  background-color: var(--primary-color);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  font-size: calc(10px + 2vmin);
  font-weight: bold;
  color: var(--secondary-color);
}

.App-header > button {
  margin-left: 16px;
}

.App-header > .error-button {
  margin-left: 24px;
  font-size: small;
}

.console-heading-holder {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  background-color: var(--primary-color);
}
.error-button {
  margin-left: 16px;
  font-size: x-small;
  background-color: red;
}

pre {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  background-color: black;
  color: chartreuse;
  height: 35vh;
}

.tabs-add-btn-holder {
  display: flex;
  flex-direction: row;
  background-color: var(--primary-color);
  align-items: last baseline;
}

.tabs {
  display: flex;
  flex-direction: row;
  padding: 0.4rem 0.4rem 0 0.4rem;
  overflow-y: scroll;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.tabs::-webkit-scrollbar {
  display: none;
}

.info-button {
  margin-left: 16px;
  font-size: x-small;
  font-weight: bold;
  background-color: var(--secondary-color);
  color: var(--primary-color);
  border-color: red;
  border-radius: 0.4rem;
  padding: 0.2rem 0.4rem;
}
