.tab {
  background-color: var(--secondary-color);
  border-color: var(--primary-color);
  color: var(--primary-color);
}

.tab-selected {
  background-color: var(--primary-color);
  border-color: var(--secondary-color);
  color: var(--secondary-color);
}

.tab,
.tab-selected {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-width: 0.2rem 0.1rem 0 0.1rem;
  border-style: solid;
  border-radius: 0.8rem 1.2rem 0 0;
  padding: 0.4rem;
  cursor: pointer;
  font-weight: bold;
  /* width: 20rem;
  height: 1.6rem;
  overflow-y: hidden; */
}

.tab-title {
  margin-right: 1rem;
  flex: 19;
  overflow: scroll;
  text-align: left;
}

.tab-title:hover {
  opacity: 0.5;
}
